<template>
    <w-data-table
        outlined
        :headers="headers"
        :items="items"
        searchable
        :filter-by="['last_name', 'first_name', 'middle_name']"
    >
        <template #item.last_name="{item}">
            {{ item | memberFullName }}
        </template>
    </w-data-table>
</template>

<script>
import { getStudentsCollection } from '@apps/school/api/students'
import hasMemberFullNameFilterMixin from '@/components/Member/mixins/hasMemberFullNameFilterMixin'
export default {
    name: 'GroupsStudents',
    mixins: [hasMemberFullNameFilterMixin],
    props: {
        uuid: String
    },
    computed: {
        headers() {
            return [
                {
                    text: this.$trans('Name'),
                    value: 'last_name'
                },
                {
                    text: this.$trans('Email'),
                    value: 'email'
                },
                {
                    text: this.$trans('Phone'),
                    value: 'phone'
                }
            ]
        }
    },
    data: () => ({
        items: []
    }),
    mounted() {
        this.fetchStudents()
    },
    methods: {
        async fetchStudents() {
            this.$lprogress.begin()

            try {
                const payload = {
                    group: this.uuid
                }

                const response = await getStudentsCollection(payload)
                this.items = response.data.data
            } finally {
                this.$lprogress.end()
            }
        }
    }
}
</script>

<style lang=scss>

</style>
